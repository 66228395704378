<template>
  <div class="reservation">
    <b-row>
        <b-col sm="12">

          <div class="reservationStatus text-center" v-if="reservation !== undefined">
            <span v-if="reservation.status == 'booked'"></span><!--niente da segnalare-->
            <p v-else-if="reservation.status == 'canceled'" class="mb-4">
              <b-alert class="d-inline" variant="danger" show><b>Questa prenotazione è stata cancellata e pertanto non più valida.</b></b-alert>
              <br><br>
            </p><!--prenotazione cancellata-->
            <p v-else class="mb-4">
              <b-alert class="d-inline" variant="danger" show><b>Questa prenotazione presenta un problema e potrebbe non essere garantita, per favore contattare subito l'assistenza.</b></b-alert>
              <br><br>
            </p><!--c'è stato un problema-->
          </div>

          <div class="reservationStatus text-center" v-if="route != 'confirm' && reservation && reservation.doubleCheck && reservation.status == 'booked'">
            <p class="mb-4">
              <br/><br/>
              <b-alert class="d-inline" :variant="reservation.doubleCheck.variant" show><b>
                {{reservation.doubleCheck.text}}
              </b></b-alert>
              <br><br>
            </p><!--c'è stato un problema-->
          </div>

            <!-- checkout mobile only -->
            <div class="mobile-only reservationIntro print-hide">
                <p>Complimenti, ottima scelta!<br>Ancora un paio di click ed avrai concluso la tua prenotazione</p>
            </div>
            <!-- checkout mobile only -->

            <div class="panel-white">
                <div class="panel-content">
                    <div class="reservation-resume">

                        <div v-if="!reservation || (reservation && reservation.status == 'booked')" class="reservation-resume-message">
                            
                            <!-- confirm only -->
                            <div class="confirm-message" v-if="Object.keys(guest).length">
                                <p class="confirm-message-heading">
                                    Complimenti {{guest.guestFirstName}} {{guest.guestLastName}}!
                                    <br>
                                    <span class="uppercase">la tua prenotazione è confermata</span>
                                </p>

                                <h4 class="text-center black mb-4 pb-2">
                                  Potrai presentarti in struttura con questa Conferma.
                                </h4>

                                <p v-if="reservation && !reservation.doubleCheck" class="print-hide">
                                  <b>
                                    Contatteremo di nuovo la struttura prima del tuo arrivo per assicurarci che sia tutto pronto per accoglierti.
                                  </b>
                                  <br><br>
                                  Garantire la sicurezza e la serenità del tuo viaggio è per noi la cosa più importante.
                                </p>

                                <div v-if="titolareCredito" class="print-hide">
                                  <p>
                                    Abbiamo inviato all'indirizzo <strong><u>{{guest.guestEmail}}</u></strong> il riepilogo della prenotazione.
                                  </p>
                                  <p>
                                    L'estratto conto della prenotazione è stato inviato all'indirizzo <strong><u>{{titolareCredito}}</u></strong>.
                                  </p>
                                  <!--vecchio copy
                                  <p>
                                      Abbiamo appena inviato all'indirizzo <strong><u>{{guest.guestEmail}}</u></strong> il voucher da presentare in hotel al check-in.
                                  </p>

                                  <p>
                                    Inviaremo all'indirizzo <strong><u>{{titolareCredito}}</u></strong> l'estratto conto della prenotazione.
                                  </p>-->
                                </div>

                                <p class="print-hide">
                                  Non esitare a contattarci per qualsiasi informazione o supporto.
                                </p>


                                <div class="buttons print-hide">
                                  <a class="btn btn-secondary" href="#" @click="print()">Stampa la Conferma da presentare in hotel</a>
                                  <!--<a class="btn btn-secondary" href="#">Salva in PDF</a>-->
                                </div>

                                <p class="green-copy print-hide">
                                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.86433 5.76911C5.74398 8.364 2.9548 10.8202 2.17441 15C5.20712 12.9066 9.21953 12.4103 11.3455 9.87908C13.5395 7.2677 13.1503 3.65589 13.2696 0C10.7168 1.19941 8.18805 1.86431 5.60051 2.45229C0.648832 3.57779 -1.44595 8.9076 1.06551 13.8614C3.84782 8.8848 5.86634 6.71878 8.86433 5.76911Z" fill="#28A745"/>
                                  </svg>
                                  Pensa all'ambiente. Stampa solo se necessario. Ti ricordiamo che potrai presentare la Conferma anche da un dispositivo mobile.
                                </p>
                            </div><!-- confirm only -->
                            
                            <div v-else class="desktop-only">
                                <p>Complimenti, ottima scelta! Ancora un paio di click ed avrai concluso la tua prenotazione</p>
                            </div>

                        </div>

                        <div class="reservation-resume-left">
                        
                            <h5 class="panel-title">Riepilogo della prenotazione</h5>

                            <div class="toggleReservation print-hide" v-b-toggle="['toggle-info-left','toggle-info-right']">
                              <div class="info">
                                <p class="name">{{bookingData.hotelName}}</p>
                                <strong>checkin:</strong> {{checkinDate}}
                                <br>
                                <strong>checkout:</strong> {{checkoutDate}}
                                <br>
                                {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 0">adulti</span><span v-else>adulto</span>
                                <span v-if="bookingData.numberOfChildren == 1">{{bookingData.numberOfChildren}} bambino</span>
                                <span v-if="bookingData.numberOfChildren > 1">{{bookingData.numberOfChildren}} bambini</span>

                                <hr />

                                <div class="policyCancellazione">
                                  <strong>Policy di cancellazione</strong>
                                  <!--cancellabile-->
                                  <div v-if="bookingData.refundable">
                                      <p>
                                        Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{bookingData.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)
                                      </p>
                                      <!--<p>
                                        A causa delle possibili incertezze dovute alla normativa Covid, è sempre preferibile che la rimborsabilità sia garantita il più possibile fino a sotto la data di partenza.
                                      </p>-->

                                      <p v-if="mixinIsWelfare()">
                                        <b>Attenzione! A seguito della cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare ma riceverai via email le istruzioni su come effettuare una nuova prenotazione entro 12 mesi, direttamente su questo sito.</b>
                                      </p>
                                      <p v-else>
                                        <b>Se procederai alla cancellazione di questa prenotazione ti verrà inviato per email un buono sconto di pari valore da utilizzare entro 12 mesi su questo sito.</b>
                                      </p>

                                  </div>
                                  <!--non cancellabile-->
                                  <div v-else>
                                    <p>
                                      Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.
                                      <!--<br>
                                      Alla luce delle incertezza causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.-->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <span class="toggler">Tutti i dettagli <SvgChevronDownToggler /></span>
                            </div>

                            <!-- confirm only -->
                            <div class="confirm-resume" v-if="Object.keys(guest).length">
                                <strong class="confirm-subtitle">Codici e riferimenti</strong>
                                <div>
                                    <!--Numero di conferma hotel: <b>{{confirmcode}}</b><br>-->
                                    Numero di conferma prenotazione: <b>{{reservation.confirmationNumber}}</b><br>
                                    Numero di itinerario: <b>{{reservation.itinerary_id}}</b><br>
                                    Viaggiatore di riferimento: <b>{{guest.guestFirstName}} {{guest.guestLastName}}</b><br>
                                    Email del viaggiatore: <b>{{guest.guestEmail}}</b><br>
                                    Telefono del viaggiatore: <b>{{guest.guestPhone}}</b><br>
                                </div>
                            </div>

                            <b-collapse id="toggle-info-left">

                              <strong class="confirm-subtitle">Nome della struttura</strong>
                              <div>{{bookingData.hotelName}}</div>
                              <hr>

                              <strong class="confirm-subtitle">Indirizzo</strong>
                              <div>
                                  <SvgPin class="svg-pin" />{{bookingData.hotelAddress + ', ' + bookingData.hotelPostalCode + ' ' + bookingData.hotelCity + ' (' + bookingData.hotelCountryCode + ')'}}
                              </div>
                              <hr>

                              <div v-if="this.$route.name == 'confirm' || this.$route.name == 'reservations'">
                                <strong class="confirm-subtitle">Numero di telefono</strong>
                                <div>{{bookingData.hotelPhone}}</div>
                                <hr>
                              </div>

                              <strong class="confirm-subtitle">Camera</strong>
                              <div>
                                {{bookingData.roomDescription}}<br/>
                                <span v-b-popover.hover.top="'Preferenza letti: richiesta non confermata soggetta a disponibilità'">Preferenza letti: {{bookingData.bedType}}</span>
                              </div>
                              <hr>

                              <strong class="confirm-subtitle">Servizi inclusi</strong>
                              <div>
                                {{ (reservation && reservation.roomValueAdds)? reservation.roomValueAdds : rateAmenities }}
                              </div>
                              <hr>

                              <strong class="confirm-subtitle">Numero di persone</strong>
                              <div>
                                  {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">Adulti</span><span v-else>Adulto</span>
                                  <span v-if="bookingData.childs.length !== 0">
                                      , {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">Bambini</span><span v-else>Bambino</span>
                                  </span>
                              </div>
                              <hr>

                              <div class="checkin-checkout-box">
                                  <div>
                                  <strong class="confirm-subtitle">Check-in</strong>
                                  <div>{{bookingData.arrivalDate}}<br/>
                                    <span v-if="bookingData.hotelDetails.checkInTime"> dalle {{bookingData.hotelDetails.checkInTime}}</span> <span v-if="bookingData.hotelDetails.checkInEndTime"> alle {{bookingData.hotelDetails.checkInEndTime}}</span></div>
                                  </div>
                                  <div>
                                  <strong class="confirm-subtitle">Check-out</strong>
                                    <div>{{bookingData.departureDate}}<br/>
                                      <span v-if="bookingData.hotelDetails.checkInTime"> entro le {{bookingData.hotelDetails.checkOutTime}}</span>
                                    </div>
                                  </div>
                              </div>

                              <hr>

                              <strong class="confirm-subtitle">Istruzioni per il checkin</strong>
                              <div v-if="bookingData.hotelDetails.specialCheckInInstructions">
                                <p v-html="bookingData.hotelDetails.specialCheckInInstructions"></p>
                              </div>
                              <div v-html="bookingData.hotelDetails.checkInInstructions"></div>
                            
                            </b-collapse>
                            <!--toggle-->

                        </div>

                          <b-collapse id="toggle-info-right" class="reservation-resume-right print-hide">

                              <div>
                                <strong class="confirm-subtitle">Informazioni importanti</strong>
                                <div>
                                  <br/>
                                  <ol>
                                    <li v-for="(item, index) in bookingData.hotelDetails.specialDetails" v-bind:key="'specialDetails-' + index">
                                      {{ item }}
                                    </li>
                                  </ol>
                                  <!--<div class="print-only"><PropertyToKnow :details="bookingData.hotelDetails" /></div>-->
                                  &nbsp;
                                  <PropertyToKnow :details="bookingData.hotelDetails" />

                                </div>
                                  
                                  <!--<span class="terms-link no-print" @click="$bvModal.show('modal-info')">
                                  &nbsp;
                                  <i>Leggi tutto ...</i>
                                  </span>
                                  
                                  <b-modal id="modal-info" size="lg" hide-footer>
                                      <template v-slot:modal-title>
                                          Informazioni importanti
                                      </template>
                                      <div class="modal-section">
                                        <PropertyToKnow :details="bookingData.hotelDetails" />
                                      </div>
                                  </b-modal>-->

                              </div>

                            <hr>

                            <strong class="confirm-subtitle">Informazioni sulla struttura</strong>

                            <div>
                              <br/>
                              <p>
                                {{bookingData.hotelDetails.location}}
                              </p>
                              <div class="print-only"><PropertyInfo :details="bookingData.hotelDetails" /></div>

                              <span class="terms-link no-print" @click="$bvModal.show('modal-property-info')">
                                  &nbsp;
                              <i>Leggi tutto ...</i>
                              </span>

                              <b-modal id="modal-property-info" size="lg" hide-footer>
                                <template v-slot:modal-title>
                                  Informazioni sulla struttura
                                </template>
                                <div class="modal-section">
                                  <PropertyInfo :details="bookingData.hotelDetails" />
                                </div>
                              </b-modal>

                            </div>

                            <hr>

                            <strong class="confirm-subtitle">Misure anti-covid</strong>
                            <br><br>

                            <!--<PropertyCovidAlert v-if="covidAlertTxt != '-'" :details="bookingData.hotelDetails" />-->
                            <PropertyConditions />


                            <div>
                              <hr>
                              <p><strong class="confirm-subtitle">Policy di cancellazione</strong></p>
                              <!--cancellabile-->
                              <div v-if="bookingData.refundable">
                                <!-- cancellabile ma non sono più in tempo
                                <div v-if="refundableBeforeDate < Date.now()">
                                  <p>Questa prenotazione poteva essere modificata entro il <b>{{bookingData.refundableBefore}}</b></p>
                                </div>
                                cancellabile e sono ancora in tempo -->
                                <div>
                                  <b-row>
                                    <b-col sm="12">
                                      <p>
                                        Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{bookingData.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)
                                      </p>
                                      <!--<p>
                                        A causa delle possibili incertezze dovute alla normativa Covid, è sempre preferibile che la rimborsabilità sia garantita il più possibile fino a sotto la data di partenza.
                                      </p>-->

                                      <p v-if="mixinIsWelfare()">
                                        <b>Attenzione! A seguito della cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare ma riceverai via email le istruzioni su come effettuare una nuova prenotazione entro 12 mesi, direttamente su questo sito.</b>
                                      </p>
                                      <p v-else>
                                        <b>Se procederai alla cancellazione di questa prenotazione ti verrà inviato per email un buono sconto di pari valore da utilizzare entro 12 mesi su questo sito.</b>
                                      </p>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                              <!--non cancellabile-->
                              <div v-else>
                                <p>Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.</p>
                                <!--<p>Alla luce delle incertezza causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.</p>-->
                              </div>

                            </div>

                          </b-collapse>
                    </div>
                </div>
            </div>

            <!-- nella rotta double-check non deve essere possibile cancellare la prenotazione-->
            <div v-if="reservation !== undefined && route != 'double-check'" class="print-hide">
              <div v-if="reservation.status == 'booked'">
                <div class="panel-white">
                  <div class="panel-content">
                    <div class="reservation-resume">
                      <!-- info rimborsabilità e cancellazioni -->
                      <div style="width:100%;">
                        <div class="reservationRefundable" v-if="cancelMessage !== ''">
                          <p>
                            <b-alert class="d-inline" variant="success" show>{{cancelMessage}}</b-alert>
                          </p>
                          <br/>
                          <p v-if="Object.keys(refundDiscountCode).length">
                          Abbiamo emesso il buono sconto <b>{{refundDiscountCode.code}}</b> del valore di <b>{{refundDiscountCode.valueNominal}}€</b> da utilizzarsi entro il <b>{{refundDiscountCode.expireAt}}</b> (gg-mm-aaaa) per una nuova prenotazione.

                            <br/><br/>
                            Questo buono ti è stato inviato anche per email insieme alla Conferma di cancellazione di questa prenotazione.
                          <br/><br/>
                          Il buono sconto ha una validità di 12 mesi da adesso

                          <br/><br/>
                          <span v-if="mixinIsWelfare()">
                            <b>Attenzione! Il buono sconto non va ad aggiungersi al credito di welfare aziendale e nemmeno potrà essere cumulato al welfare aziendale cioè non potrà essere usato per scontare un'altra prenotazione che si stia effettuando con il credito welfare.</b>
                          </span>
                          <br/><br/>
                          <b>Puoi usare il buono fin da subito</b>
                          <br/><br/>
                          <a :href="refundDiscountCode.link"><b-button variant="" >Usa il buono adesso</b-button></a>
                          </p>



                        </div>
                        <div class="reservationRefundable" v-else>
                            <p><strong class="confirm-subtitle">Policy di cancellazione</strong></p>
                            <!--cancellabile-->
                            <div v-if="reservation.refundable">
                              <!-- cancellabile ma non sono più in tempo -->
                              <div v-if="refundableBeforeDate < Date.now()">
                                <p>Questa prenotazione poteva essere modificata entro il <b>{{reservation.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)</p>
                              </div>
                              <!-- cancellabile e sono ancora in tempo -->
                              <div v-else>
                                <b-row>
                                  <b-col sm="12">
                                    <p>
                                      Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{reservation.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)
                                    </p>
                                    <!--<p>
                                      A causa delle possibili incertezze dovute alla normativa Covid, è sempre preferibile che la rimborsabilità sia garantita il più possibile fino a sotto la data di partenza.
                                    </p>-->
                                    <p v-if="mixinIsWelfare()">
                                      <b>Attenzione! A seguito della cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare ma riceverai via email le istruzioni su come effettuare una nuova prenotazione entro 12 mesi, direttamente su questo sito.</b>
                                    </p>
                                    <p v-else>
                                      <b>Se procederai alla cancellazione di questa prenotazione ti verrà inviato per email un buono sconto di pari valore da utilizzare entro 12 mesi su questo sito.</b>
                                    </p>

                                    <b-button variant="danger" v-b-modal.modal-cancel>Cancella la prenotazione</b-button>
                                    <b-modal id="modal-cancel" title="Cancella la prenotazione" hide-footer size="lg">
                                      <div class="mt-4 mb-4 ml-2 mr-2 text-center">
                                      <p v-if="!loading">
                                        <span v-if="mixinIsWelfare()">
                                          <b>Attenzione! A seguito della cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare ma riceverai via email le istruzioni su come effettuare una nuova prenotazione entro 12 mesi, direttamente su questo sito.</b>
                                        </span>
                                        <span v-else>
                                          <b>Se procederai alla cancellazione di questa prenotazione ti verrà inviato per email un buono sconto di pari valore da utilizzare entro 12 mesi su questo sito.</b>
                                        </span>

                                        <br/><br/>
                                        <b>Sei sicuro di voler cancellare questa prenotazione?</b>
                                        <br/><br/>
                                        <b-button variant="danger" @click="cancelReservation">Si, cancella la prenotazione</b-button>
                                      </p>
                                      <p v-else>
                                        Attendi un attimo, stiamo cancellando la prenotazione.
                                        <br/><br/>
                                        <b>Non lasciare questa pagina.</b>
                                        <br/><br/>
                                        <Spinner />
                                      </p>
                                      </div>
                                    </b-modal>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                            <!--non cancellabile-->
                            <div v-else>
                              <p>Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.</p>
                              <!--<p>Alla luce delle incertezza causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.</p>-->
                            </div>
                        </div>
                      </div>
                      <!-- /info rimborsabilità e cancellazioni -->
                        </div>
                    </div>
                </div>
              </div>
            </div>

            <!-- checkout mobile only
            <div class="mobile-only mb-4">
                <SidebarCheckout v-if="!this.mixinUseTicketCompliments() && this.mixinIsWelfare() && !Object.keys(guest).length" :bookingData="this.$store.state.bookingData"  />
            </div>
            checkout mobile only -->
        </b-col>
    </b-row>
  </div>
</template>

<script>
import Spinner from './../atoms/spinner'
import PropertyInfo from './../blocks/property/property-info'
import PropertyToKnow from './../blocks/property/property-toKnow'
//import PropertyCovidAlert from './../blocks/property/property-covid-alert'
import PropertyConditions from './../blocks/property/property-conditions'
import SvgPin from '../svg/pin'
import SvgChevronDownToggler from '../svg/chevron-down-toggler'
import Api from '../../api'
import Vue from "vue";
export default {
    name: 'reservation',
    components:
    {
      /*
      SidebarCheckout: () => import('./../blocks/sidebar/sidebar-checkout').then(function(SidebarCheckout) {
        window.console.log('async SidebarCheckout Component loaded')
        return SidebarCheckout
      }),
      */
      PropertyInfo,
      PropertyToKnow,
      SvgPin,
      SvgChevronDownToggler,
      //PropertyCovidAlert,
      PropertyConditions,
      Spinner,
    },
    props:
    {
      confirmcode: String,
      itinerary_id: String,
      bookingData: Object,
      guest: Object,
      reservation: Object
    },
    methods:{
        cancelReservation(){
          let _this = this

          _this.loading = true

          Api
            .cancelReservation({
                confirmcode: _this.reservation.confirmcode,
                itinerary_id: _this.reservation.itinerary_id,
                bookingData: _this.bookingData,
            })
            .then((response) => {
              _this.$bvModal.hide('modal-cancel')
              _this.loading = false

              // response false = http != 200
              if(response){
                _this.cancelMessage = response.msg
                if(response.refundDiscountCode) _this.refundDiscountCode = response.refundDiscountCode
              }

            })
        },
        formatDate: function(data){
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(data)
          return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        },
        print: function(){
          // ps. se per mila motivi la pagina sta ancora caricando, non stampa
          window.print()
        },
        rateAmenitiesTxt: function(){
          if(this.bookingData.rateAmenities.length == 0){
            this.rateAmenities = 'solo pernotto'
          }else{
            let amenities = '';
            for (let key in this.bookingData.rateAmenities) {
              if(this.configAmenities[key]) amenities += this.configAmenities[key].label + ', '
            }

            // capitalizzo e tolgo ", " dalla coda (-3 perchè sto accorciando la stringa senza il primo char)
            amenities = amenities.charAt(0).toUpperCase() + amenities.slice(1).substr(0, amenities.length -3)

            this.bookingData.rateAmenitiesTxt = amenities // alza warning, rateAmenitiesTxt lo uso solo qui per passarmelo al backend.. in seguito vedremo
            this.rateAmenities = amenities
          }
        },
    },
    computed:{
      /*checkinDate: function() {
        return this.formatDate(this.bookingData.arrivalDate)
      },
      checkoutDate: function(){
        return this.formatDate(this.bookingData.departureDate)
      },*/
      checkinDate: function() {
        // reservation arriva da una verifica itinerario con api expedia (così se venissero cambiate la date, recuperiamo quelle aggiornate)
        if(this.reservation && this.reservation.checkin) return this.reservation.checkin

        //return this.formatDate(this.bookingData.arrivalDate)
        return this.bookingData.arrivalDate.split("-").reverse().join("-")
      },
      checkoutDate: function(){
        if(this.reservation && this.reservation.checkout) return this.reservation.checkout

        //return this.formatDate(this.bookingData.departureDate)
        return this.bookingData.departureDate.split("-").reverse().join("-")
      },
      refundableBeforeDate: function(){
        let fullDate = (this.bookingData.refundableBefore ? this.bookingData.refundableBefore : '')
        let date = fullDate.split(' ')[0]
        let hour = fullDate.split(' ')[1]
        let dateString = Date.parse(date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0]+'T'+hour)
        // 23-08-2020 00:01:00 -> 2011-10-10T14:48:00
        return dateString
      },
      titolareCredito: function(){

        if(Vue.prototype.$config.integration && Vue.prototype.$config.integration.welfare && Vue.prototype.$config.integration.email){
          return Vue.prototype.$config.integration.email
        }

        return this.guest.guestEmail
      }
    },
    data() {
      return {
        //titolareCredito: (Vue.prototype.$config.integration ? Vue.prototype.$config.integration.email : ''),
        configAmenities: Vue.prototype.$config.amenities,
        rateAmenities: '',
        loading: false,
        cancelMessage: '',
        refundDiscountCode: [],
        site: Vue.prototype.$config.site,
        route: this.$router.currentRoute.name,
        covidAlertTxt: Vue.prototype.$config.guiSettings.covidAlertTxt,
      }
    },
    created: function() {
      this.rateAmenitiesTxt();
    },
}
</script>

<style lang="scss">
  
  
  

  .reservation
  {
    ol {
      padding-left: 18px;
    }

    ol>li{
      list-style-type: disc;
      margin-bottom: 15px;
    }

    .reservation-resume
    {
        
        strong
        {
            font-weight:600;
        }
        .panel-title
        {
            margin-bottom:2rem;
        }
        @include media-breakpoint-up(lg)
        {
            padding:$grid-gutter-width/4 $grid-gutter-width/2;
        }
    }

    .reservation-resume-message
    {
        width:100%;
    }

    .reservationRefundable
    {
      width:100%;
      margin-top:1rem;
      margin-bottom:1rem;
      text-align:center;
    }

    .reservation-resume-left
    {
        margin-top:1rem;
        padding-bottom:1rem;    
    }

    .reservation-resume-right
    {
        margin-top:1rem;
        padding-bottom:1rem;
    }

    @include media-breakpoint-up(lg)
    {
        .reservation-resume-left
        {
            margin-top:1rem;
            padding-top:$grid-gutter-width/2;
            padding-bottom:$grid-gutter-width/2;    
        }

        .reservation-resume-right
        {
            margin-top:1rem;
            padding-top:$grid-gutter-width/2;
            padding-bottom:$grid-gutter-width/2;
        }
    }

    .confirm-subtitle
    {
        font-size: 19px;
    }

    .confirm-message
    {
        font-size:16px;
        margin-bottom:2rem;
        .buttons
        {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top:2rem;
            a
            {
                //flex:1;
                margin:1rem;
                //max-width: 200px;
            }
        }
    }
    .confirm-message-heading
    {
        text-align:center;
        font-weight: 600;
        font-size:28px;
        margin-bottom:3rem;
    }
    .confirm-resume
    {
        border:3px solid $tertiary;
        margin-bottom:3rem;
        padding:0.75rem 1rem;
        b
        {
            font-weight:600;
        }
    }


    @include media-breakpoint-up(lg)
    {
      .panel-title
      {
        text-align: left;
      }
        .reservation-resume
        {
            display:flex;
            flex-wrap:wrap;
        }
        .reservation-resume-left
        {
          @include media-breakpoint-up(xl){
            width:50%;
            padding-right:$grid-gutter-width;
            border-right:1px solid $light-gray;
          }
        }
        .reservation-resume-right
        {
          @include media-breakpoint-up(xl){
            width:calc(50% - #{$grid-gutter-width});
            margin-left: $grid-gutter-width/2;
          }
        }
    }

    @include media-breakpoint-only(lg)
    {
        .reservation-resume
        {
            padding:0;
        }
    }
    @include media-breakpoint-down(md)
    {
      .reservation-resume-left
      {
        margin-top:0;
        margin-bottom:0;
        padding-bottom:0;
      }
      margin-bottom:1rem;
      #toggle-info-left
      {
        margin-top:3rem;
      }
    }

    .reservationIntro
    {
      text-align: center;
      margin-bottom:2rem;
      font-size: 14px;
      font-weight: 500;
    }
    .toggleReservation
    {
      text-align:center;
      .info
      {
        margin-bottom:1rem;
      }
      .name
      {
        font-weight: 500;
        font-size: 18px;
        margin-bottom:1rem;
      }
      .btn
      {
      }
      .policyCancellazione
      {
        font-size: 14px;
        margin-top:1rem;
      }
      .toggler
      {
        font-weight:500;
        svg
        {
          width: 26px;
          margin-left: 4px;          
        }        
      }
    }
    @include media-breakpoint-up(lg)
    {
      #toggle-info-left,
      #toggle-info-right
      {
        display: block !important;
      }
      .toggleReservation
      {
        display:none;
      }
    }

    .green-copy {
      display: block;
      color:#28A745;
      text-align:center;
      margin:1rem 0 2rem 0;
    }

    .svg-pin {
      margin-right: .5rem;
      path {
        fill: $text;
      }
    }
  }

  .page-reservations {
    .checkin-checkout-box {
      display: flex;
      &>* {
        margin-right:2rem;
      }
    }
    @media print {
      .customizer,
      #debug-alert,
      .sticky-banner,
      #siteHeader .menu,
      #siteHeader .toggle {
        display: none !important;
      }
      #toggle-info-left {
        display: block !important;
      }
      #siteHeader {
        height: 50px;
      }
      #main,
      #content,
      .reservation {
        max-width: 100% !important;
        margin:0 !important;
      }
      #checkout {
        padding-top:0;
      }
      .reservation-resume,
      .reservation-resume-message,
      .panel-content,
      .confirm-message,
      .confirm-message-heading {
        padding-top:0 !important;
        margin-top:0 !important;
      }
      hr {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
      .confirm-message-heading {
        margin-bottom:0;
      }
      .text-center.black {
        margin: 1rem auto !important;
        font-weight: normal;
      }
      .reservation-resume-left {
        margin-top:0 !important;
        padding-top:0 !important;
      }
    }
  }
</style>