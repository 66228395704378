<template>
    <div id="checkout">
        <Spinner v-if="loading" />
        <b-container fluid v-else class="mb-4 pb-4">
            
            <!-- if authenticated -->
            <div id="main">
                <div id="content">
                    <div v-if="redirect && (mixinIsAuthenticated() && config.integration.integration !== 'jointly')" class="panel-white">
                        <div class="panel-content">
                            Verrai reindirizzato al tuo portale tra <b>{{redirectCounter}}</b> secondi... <Spinner class="ml-2"/>
                        </div>
                    </div>
                    <Reservation
                        :bookingData="bookingData"
                        :guest="guest"
                        :reservation="reservation"
                    />
                </div>
                <Sidebar v-if="(route == 'confirm') && Object.values(bookingData).length" />
            </div>

            <!-- if NOT authenticated 
            <b-container v-else class="mb-4 pb-4 mt-4 pt-4">
                <Reservation
                    :bookingData="bookingData"
                    :guest="guest"
                    :reservation="reservation"
                />
            </b-container>-->
        </b-container>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Sidebar from '../blocks/sidebar/sidebar'
    import Spinner from '../atoms/spinner'
    import Reservation from '../blocks/reservation'
    import Api from '../../api'

    export default {
        name: 'confirm',
        metaInfo(){
          return {
            title: 'conferma',
            meta: [
                { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
              ]
            }
        },
        components:
        {
            Spinner,
            Sidebar,
            Reservation
        },
        props:
        {
            confirmcode: String,
            itinerary_id: String,
            newAffiliateReferenceId: {
              type: String,
              default: '',
            },
            redirectIfConfirmedRedirectTo: {
              type: Boolean,
              default: false,
            },
            prerenderStatusCode: {
              type: String,
              default: '200',
            },
            ignoreRedirect: {
              type: Boolean,
              default: false,
            }
        },
        computed:
        {
            bookingData: {
                get() { return this.$store.state.bookingData },
                set(value) { this.mixinSendMutation('setBookingData',value) }
            }
        },
        data()
        {
            return {
                //bookingData: {},
                route: this.$router.currentRoute.name,
                config: Vue.prototype.$config,
                guest: {},
                reservation: {},
                loading: true,
                redirect: false,
                redirectCounter: 30
            }
        },
        methods: {
        },
        mounted() {

            if(this.newAffiliateReferenceId != '') this.mixinSendMutation('setAffiliateReferenceId', this.newAffiliateReferenceId)

            let _this = this
            if(!this.ignoreRedirect && _this.mixinIsAuthenticated() && Vue.prototype.$config.integration.orderConfirmedRedirectTo)
            {
                _this.redirect = true
                let counter = window.setInterval(function(){
                    if(_this.redirectCounter == 0)
                    {
                        clearInterval(counter)
                        window.location.href = Vue.prototype.$config.integration.orderConfirmedRedirectTo
                    }
                    _this.redirectCounter = _this.redirectCounter - 1
                },1000)
            }
            Api
                .getReservation({
                    //token:
                    confirmcode: _this.confirmcode,
                    itinerary_id: parseInt(_this.itinerary_id)
                })
                .then((response) => {
                    _this.bookingData = response.bookingData
                    _this.guest = response.guest
                    _this.reservation = response.reservation
                    _this.loading = false
                })         
        }
    }
</script>

<style lang="scss">

    #checkout {
        @include media-breakpoint-up(lg) {
          padding-top: 250px;
        }
    }

    #confirm
    {
      @include media-breakpoint-up(lg)
      {
        #sidebar {
          padding-right:0;
          padding-left:$grid-gutter-width;
        }
      }
    }

    @include media-breakpoint-down(md)
    {
        #checkout
        {
            #main
            {
                #sidebar
                {
                    max-width:100% !important;
                    padding:0;
                }
                .sidebar-inner
                {
                width:100%;
                max-width:100%;
                margin:0;
                margin-top:$grid-gutter-width/2;
                }
            }
        }
    }    
</style>